import React, { createContext, forwardRef, useContext } from "react";
import { useId } from "@radix-ui/react-id";
import { Slot } from "@radix-ui/react-slot";
import { Controller } from "react-hook-form";
import { cn } from "../../lib/utils";
import { Label } from "../ui/label";
import { FormProvider } from 'react-hook-form';

const Form = React.forwardRef(({ children, ...props }, ref) => {
  return <FormProvider {...props}>{children}</FormProvider>;
});

const FormFieldContext = createContext({});

const FormField = forwardRef(({ name, ...props }, ref) => {
  return (
    <FormFieldContext.Provider value={{ name }}>
      <Controller name={name} {...props} />
    </FormFieldContext.Provider>
  );
});

const useFormField = () => {
  const fieldContext = useContext(FormFieldContext);

  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }

  return fieldContext;
};

const FormItemContext = createContext({});

const FormItem = forwardRef(({ className, ...props }, ref) => {
  const id = useId();

  return (
    <FormItemContext.Provider value={{ id }}>
      <div ref={ref} className={cn("space-y-2", className)} {...props} />
    </FormItemContext.Provider>
  );
});

FormItem.displayName = "FormItem";

const FormLabel = forwardRef(({ className, ...props }, ref) => {
  const fieldContext = useFormField();

  return (
    <Label
      ref={ref}
      className={cn(fieldContext.error && "text-destructive", className)}
      htmlFor={fieldContext.name}
      {...props}
    />
  );
});

FormLabel.displayName = "FormLabel";

const FormControl = forwardRef(({ ...props }, ref) => {
  const fieldContext = useFormField();

  return (
    <Slot
      ref={ref}
      id={fieldContext.name}
      aria-describedby={fieldContext.error ? `${fieldContext.name}-description ${fieldContext.name}-message` : `${fieldContext.name}-description`}
      aria-invalid={!!fieldContext.error}
      {...props}
    />
  );
});

FormControl.displayName = "FormControl";

const FormDescription = forwardRef(({ className, ...props }, ref) => {
  const fieldContext = useFormField();

  return (
    <p
      ref={ref}
      id={`${fieldContext.name}-description`}
      className={cn("text-sm text-muted-foreground", className)}
      {...props}
    />
  );
});

FormDescription.displayName = "FormDescription";

const FormMessage = forwardRef(({ className, children, ...props }, ref) => {
  const fieldContext = useFormField();
  const body = fieldContext.error ? String(fieldContext.error?.message) : children;

  if (!body) {
    return null;
  }

  return (
    <p
      ref={ref}
      id={`${fieldContext.name}-message`}
      className={cn("text-sm font-medium text-destructive", className)}
      {...props}
    >
      {body}
    </p>
  );
});

FormMessage.displayName = "FormMessage";

export {
  useFormField,
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField,
};
