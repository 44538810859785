import React, { createContext, useContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { nanoid } from "nanoid";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getSymbolForCurrency } from "../lib/utils";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBzbzo2WIN8500RRX_DzeRpO8_32sGTCvU",
  authDomain: "dtfbuulder.firebaseapp.com",
  projectId: "dtfbuulder",
  storageBucket: "dtfbuulder.appspot.com",
  messagingSenderId: "103655701196",
  appId: "1:103655701196:web:a5cc0cf77cab635fcf6069",
  measurementId: "G-440KTB287V",
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Create a new context
const FirebaseContext = createContext();

// Custom hook to access Firebase context
export const useFirebase = () => useContext(FirebaseContext);

export const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [configs, setSettings] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        try {
          const session = auth.currentUser;
          if (!session) throw new Error(`No session`);
          const settingsRef = doc(db, "settings", session.uid);
          const settingsSnapshot = await getDoc(settingsRef);
          if (settingsSnapshot.exists()) {

            const settingData = settingsSnapshot.data();
            setSettings(settingData);
            const currencySymbol = getSymbolForCurrency(settingData.currency);
            localStorage.setItem("currencySymbol", currencySymbol);
          } else {
            toast.error("Settings document not found");
          }
        } catch (error) {
          // toast.error("Error fetching settings:", error);
        }
      }
    });

    // Cleanup function
    return () => unsubscribe();
  }, []);

  const updateEditorPreferences = async (data) => {
    try {
      const session = auth.currentUser;
      if (!session) throw new Error(`No session`);
      const orderRef = doc(db, "settings", session.uid);
      await updateDoc(orderRef, data);
      toast.success("Editor preferences updated successfully");
    } catch (error) {
      toast.error("Error updating editor preferences:", error);
    }
  };

  const updateSheetSize = async (data) => {
    try {
      const session = auth.currentUser;
      if (!session) throw new Error(`No session`);
      const orderRef = doc(db, "settings", session.uid);
      // await updateDoc(orderRef, {
      //   sizes: arrayUnion({
      //     ...data,
      //     key: nanoid(),
      //   }),
      // });
      await updateDoc(orderRef, {
        sizes: data
      });
      toast.success("Sheet Size Update successfully");
    } catch (error) {
      toast.error("Error updating sheet size:", error);
    }
  };

  const removeOrder = async (data) => {
    try {
      const session = auth.currentUser;
      if (!session) throw new Error(`No session`);
      const orderRef = doc(db, "settings", session.uid);
      await updateDoc(orderRef, {
        sizes: arrayRemove(data),
      });
      toast.success("Order removed successfully");
    } catch (error) {
      toast.error("Error removing order:", error);
    }
  };

  const updateCurrencyAction = async (data) => {
    try {
      const session = auth.currentUser;
      if (!session) throw new Error(`No session`);
      const orderRef = doc(db, "settings", session.uid);
      await updateDoc(orderRef, data, { merge: true });
      toast.success("Currency settings updated successfully");
    } catch (error) {
      toast.error("Error updating currency settings:", error);
    }
  };

  const updateLegalName = async (data) => {
    try {
      const session = auth.currentUser;
      if (!session) throw new Error(`No session`);
      const orderRef = doc(db, "settings", session.uid);
      await updateDoc(orderRef, data, { merge: true });
      toast.success("Legal name updated successfully");
    } catch (error) {
      toast.error("Error updating legal name:", error);
    }
  };

  const updateBleedAction = async (data) => {
    try {
      const session = auth.currentUser;
      if (!session) throw new Error(`No session`);
      const orderRef = doc(db, "settings", session.uid);
      await updateDoc(orderRef, data, { merge: true });
      toast.success("Bleed action updated successfully");
    } catch (error) {
      toast.error("Error updating bleed action:", error);
    }
  };
  
  const updateEmailSetting = async (data) => {
    try {
      const session = auth.currentUser;
      if (!session) throw new Error(`No session`);
      const orderRef = doc(db, "settings", session.uid);
      await updateDoc(orderRef, data, { merge: true });
      toast.success("Email Setting updated successfully");
    } catch (error) {
      toast.error("Error updating email setting:", error);
    }
  };

  const updateCheckoutAction = async (data) => {
    try {
      const session = auth.currentUser;
      if (!session) throw new Error(`No session`);
      const orderRef = doc(db, "settings", session.uid);
      await updateDoc(orderRef, data, { merge: true });
      toast.success("Checkout action updated successfully");
    } catch (error) {
      toast.error("Error updating checkout action:", error);
    }
  };

  const updateMinQtyAction = async (data) => {
    try {
      const session = auth.currentUser;
      if (!session) throw new Error(`No session`);
      const orderRef = doc(db, "settings", session.uid);
      await updateDoc(orderRef, data, { merge: true });
      toast.success("Minimum quantity settings updated successfully");
    } catch (error) {
      toast.error("Error updating checkout action:", error);
    }
  };
  const updateShippingAction = async (data) => {
    try {
      const session = auth.currentUser;
      if (!session) throw new Error(`No session`);
      const orderRef = doc(db, "settings", session.uid);
      await updateDoc(orderRef, data, { merge: true });
      toast.success("Shipping settings updated successfully");
    } catch (error) {
      toast.error("Error Shipping settings action:", error);
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        user,
        auth,
        db,
        configs,
        updateSheetSize,
        removeOrder,
        updateCurrencyAction,
        updateEditorPreferences,
        updateLegalName,
        updateBleedAction,
        updateEmailSetting,
        updateCheckoutAction,
        updateMinQtyAction,
        updateShippingAction,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
