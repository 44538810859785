import { db } from "../firebase";
import { collection, query, getDocs,orderBy,where } from "firebase/firestore";

const uid = localStorage.getItem('uid');

export const fetchOrders = async () => {
  try {
    const ordersQuery = query(collection(db, 'all_orders', uid, 'orders'));
    const querySnapshot = await getDocs(ordersQuery);
    const totalOrderCount = querySnapshot.size;

    return totalOrderCount;
  } catch (error) {
    console.error("Error fetching orders:", error);
    return 0;
  }
};

export const fetchRevenue = async () => {
  try {
    const ordersQuery = query(collection(db, 'all_orders', uid, 'orders'));
    const querySnapshot = await getDocs(ordersQuery);
    
    let totalRevenue = 0;
    querySnapshot.forEach((doc) => {
      const orderData = doc.data();
      if (orderData.status === "COMPLETED") {
        totalRevenue += parseFloat(orderData.total_cost) || 0;
      }
    });
    return totalRevenue;
  } catch (error) {
    console.error("Error fetching revenue:", error);
    return 0;
  }
};

export const fetchAveragePrice = async () => {
  try {
    const ordersQuery = query(collection(db, 'all_orders', uid, 'orders'));
    const querySnapshot = await getDocs(ordersQuery);
    
    let totalPrice = 0;
    let orderCount = 0;
    querySnapshot.forEach((doc) => {
      const orderData = doc.data();
      totalPrice += parseFloat(orderData.price) || 0;
      orderCount++;
    });

    return orderCount > 0 ? totalPrice / orderCount : 0;
  } catch (error) {
    console.error("Error fetching average price:", error);
    return 0;
  }
};

export const ordersDataLatest = async () => {
  try {
    if (!uid) {
      throw new Error("uid is undefined");
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    // const fiveDaysAgo = new Date();
    // fiveDaysAgo.setDate(today.getDate() - 4);
    const ordersQuery = query(
      collection(db, 'all_orders', uid, 'orders'),
      where('created_at', '>=', today),
      // where('created_at', '>=', fiveDaysAgo),
      orderBy('created_at', 'desc')
    );
    const querySnapshot = await getDocs(ordersQuery);
    
    const orderData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return orderData;
  } catch (error) {
    console.error("Error fetching latest orders:", error);
    throw error;
  }
};