import React, { useState } from "react";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

function Header() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const handleLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      localStorage.removeItem("token");
      localStorage.removeItem("uid");

      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleClick = () => {
    setIsToggled(!isToggled);
  };

  if (isToggled) {
    document.body.classList.add("SideBarShow");
  } else {
    document.body.classList.remove("SideBarShow");
  }

  if (window.innerWidth <= 991) {
    document.body.classList.add("SideBarShow");
  } 
  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex logo-toggle-iconDiv">
            <div className="navbar-brand-box">
              <a href="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src="../assets/images/logo.png" alt="" height="30" />
                </span>
                <span className="logo-lg">
                  <img src="assets/images/logo.png" alt="" height="30" />
                </span>
              </a>

              <a href="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img
                    src="../assets/images/logo-light.png"
                    alt=""
                    height="30"
                  />
                </span>
                <span className="logo-lg">
                  <img src="../assets/images/logo.png" alt="" height="19" />
                </span>
              </a>
            </div>

            {/* <button
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
              onClick={handleClick}
            >
              <i className="fa fa-fw fa-arrow-left"></i>
              <i className="fa fa-fw fa-arrow-right"></i>
            </button> */}

            {/* <form className="app-search d-none d-lg-block">
                            <div className="position-relative">
                                <input type="text" className="form-control" placeholder="Search..."/>
                                <span className="bx bx-search-alt"></span>
                            </div>
                        </form> */}

            {/* <div className="dropdown dropdown-mega d-none d-lg-block ms-2">
                            <button type="button" className="btn header-item waves-effect" data-bs-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                <span key="t-megamenu">Mega Menu</span>
                                <i className="mdi mdi-chevron-down"></i> 
                            </button>
                            <div className="dropdown-menu dropdown-megamenu">
                                <div className="row">
                                    <div className="col-sm-8">
    
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h5 className="font-size-14" key="t-ui-components">UI Components</h5>
                                                <ul className="list-unstyled megamenu-list">
                                                    <li>
                                                        <a href="#" key="t-lightbox">Lightbox</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-range-slider">Range Slider</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-sweet-alert">Sweet Alert</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-rating">Rating</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-forms">Forms</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-tables">Tables</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-charts">Charts</a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="col-md-4">
                                                <h5 className="font-size-14" key="t-applications">Applications</h5>
                                                <ul className="list-unstyled megamenu-list">
                                                    <li>
                                                        <a href="#" key="t-ecommerce">Ecommerce</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-calendar">Calendar</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-email">Email</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-projects">Projects</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-tasks">Tasks</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-contacts">Contacts</a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="col-md-4">
                                                <h5 className="font-size-14" key="t-extra-pages">Extra Pages</h5>
                                                <ul className="list-unstyled megamenu-list">
                                                    <li>
                                                        <a href="#" key="t-light-sidebar">Light Sidebar</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-compact-sidebar">Compact Sidebar</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-horizontal">Horizontal layout</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-maintenance">Maintenance</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-coming-soon">Coming Soon</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-timeline">Timeline</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-faqs">FAQs</a>
                                                    </li>
                            
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <h5 className="font-size-14" key="t-ui-components">UI Components</h5>
                                                <ul className="list-unstyled megamenu-list">
                                                    <li>
                                                        <a href="#" key="t-lightbox">Lightbox</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-range-slider">Range Slider</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-sweet-alert">Sweet Alert</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-rating">Rating</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-forms">Forms</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-tables">Tables</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" key="t-charts">Charts</a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="col-sm-5">
                                                <div>
                                                    <img src="assets/images/megamenu-img.png" alt="" className="img-fluid mx-auto d-block"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div> */}
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block">
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-notifications-dropdown"
              >
                <div data-simplebar style={{ maxHeight: "230px" }}>
                  <a href="#" className="text-reset notification-item">
                    <div className="d-flex">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          <i className="bx bx-cart"></i>
                        </span>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1" key="t-your-order">
                          Your order is placed
                        </h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1" key="t-grammer">
                            If several languages coalesce the grammar
                          </p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline"></i>{" "}
                            <span key="t-min-ago">3 min ago</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="text-reset notification-item">
                    <div className="d-flex">
                      <img
                        src="assets/images/users/avatar-3.jpg"
                        className="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div className="flex-grow-1">
                        <h6 className="mb-1">James Lemire</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1" key="t-simplified">
                            It will seem like simplified English.
                          </p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline"></i>{" "}
                            <span key="t-hours-ago">1 hours ago</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="#" className="text-reset notification-item">
                    <div className="d-flex">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title bg-success rounded-circle font-size-16">
                          <i className="bx bx-badge-check"></i>
                        </span>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1" key="t-shipped">
                          Your item is shipped
                        </h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1" key="t-grammer">
                            If several languages coalesce the grammar
                          </p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline"></i>{" "}
                            <span key="t-min-ago">3 min ago</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>

                  <a href="#" className="text-reset notification-item">
                    <div className="d-flex">
                      <img
                        src="assets/images/users/avatar-4.jpg"
                        className="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div className="flex-grow-1">
                        <h6 className="mb-1">Salena Layfield</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1" key="t-occidental">
                            As a skeptical Cambridge friend of mine occidental.
                          </p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline"></i>{" "}
                            <span key="t-hours-ago">1 hours ago</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="p-2 border-top d-grid">
                  <a
                    className="btn btn-sm btn-link font-size-14 text-center"
                    href="#"
                  >
                    <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                    <span key="t-view-more">View More..</span>
                  </a>
                </div>
              </div>
            </div>

            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {/* <img className="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg"
                                    alt="Header Avatar"/> */}
                <i className="bx bx-user rounded-circle header-profile-user"></i>
                <span className="d-none d-xl-inline-block ms-1" key="t-henry">
                  {auth.currentUser && auth.currentUser.email}
                </span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <a className="dropdown-item" href="#"><i className="bx bx-user font-size-16 align-middle me-1"></i> <span key="t-profile">Profile</span></a>
                                <a className="dropdown-item" href="#"><i className="bx bx-wallet font-size-16 align-middle me-1"></i> <span key="t-my-wallet">My Wallet</span></a>
                                <a className="dropdown-item d-block" href="#"><span className="badge bg-success float-end">11</span><i className="bx bx-wrench font-size-16 align-middle me-1"></i> <span key="t-settings">Settings</span></a>
                                <a className="dropdown-item" href="#"><i className="bx bx-lock-open font-size-16 align-middle me-1"></i> <span key="t-lock-screen">Lock screen</span></a>
                                <div className="dropdown-divider"></div> */}
                <a
                  className="dropdown-item text-danger"
                  href="#"
                  onClick={handleLogout}
                >
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
                  <span key="t-logout">Logout</span>
                </a>
              </div>
            </div>

            {/* <div className="dropdown d-inline-block">
                            <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
                                <i className="bx bx-cog bx-spin"></i>
                            </button>
                        </div> */}
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
