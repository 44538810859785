import React, { useEffect, useState } from "react";
import "../assets/css/style.css";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import BleedSettings from "../components/BleedSettings";
import CheckoutSettings from "../components/CheckoutSettings";
import CopyrightNotice from "../components/CopyrightNotice";
import CurrencySettings from "../components/CurrencySettings";
import EditorSettings from "../components/EditorSettings";
import MOQSettings from "../components/MOQSettings";
import ShippingSettings from "../components/ShippingSettings";
import SheetSizes from "../components/SizeSettings";
import { useFirebase } from "../context/SettingAction";
import { Loader2 } from "lucide-react";
import EmilSettings from "../components/EmilSettings";

function Settings() {
  const { configs } = useFirebase();

  const [config, setConfig] = useState({});

  useEffect(() => {
    setConfig(configs);
  }, [configs]);

  // if (!config) {
  //   console.log("data not found")
  //   setLoading(true)
  //   return <div>Loading...</div>;
  // }

  return (
    <>
      <Header />
      <Sidebar />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Settings</h4>
                </div>
              </div>
            </div>
            {/* {!config ? (
              <Loader2
                className="mr-2 h-4 w-4 animate-spin mx-auto"
                style={{ height: 50, width: 50, color: "grey" }}
              />
              // <h1> Setting Data Not Exist</h1>
            ) : ( */}
            <div className="flex-1" key={JSON.stringify(config)}>
              <div className="flex flex-1 flex-col px-12 py-6 items-center justify-center space-y-4 setting-page-Div">
                <SheetSizes /*sheetSizes={config.sizes}*/ key="SheetSizes" />
                <CurrencySettings /*{...config}*/ key="CurrencySettings" />
                {config && <EditorSettings {...config} key="EditorSettings" />}
                {config && <BleedSettings {...config} key="BleedSettings" />}
                {config?.minQty && (
                  <MOQSettings quantity={config?.minQty} key="MOQSettings" />
                )}
                {config?.shipping && (
                  <ShippingSettings
                    shipping={config.shipping}
                    key="ShippingSettings"
                  />
                )}
                {config && (
                  <CheckoutSettings {...config} key="CheckoutSettings" />
                )}
                {config?.legalName && (
                  <CopyrightNotice
                    legalName={config.legalName}
                    key="CopyrightNotice"
                  />
                )}
                {config && <EmilSettings {...config} key="EmilSettings" />}
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Settings;
