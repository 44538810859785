import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

const minutesAfterInactivity = 10; // 10 minutes of inactivity before logout

const AutoLogout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        // Trigger logout function after specified minutes of inactivity
        (async function () {
          const auth = getAuth();
          await signOut(auth);
          localStorage.removeItem("token");
          localStorage.removeItem("uid");
        })();
        navigate("/");
      }, minutesAfterInactivity * 60 * 1000); // Convert minutes to milliseconds
    };

    const handleActivity = () => {
      resetTimer();
    };

    // Attach event listeners for user activity
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    // Initial setup
    resetTimer();

    // Cleanup event listeners on component unmount
    return () => {
      clearTimeout(timer);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, [navigate]);

  return null; // AutoLogout doesn't render any UI
};

export default AutoLogout;
