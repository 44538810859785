import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setEmailError('');
    setPasswordError('');
    setError('');

    if (!email.trim() && !password.trim()) {
      setEmailError('Please enter an email.');
      setPasswordError('Please enter a password.');
      return;
    }

    if (!email.trim()) {
      setEmailError('Please enter an email.');
      return;
    }

    if (!password.trim()) {
      setPasswordError('Please enter a password.');
      return;
    }

    try {
      const auth = getAuth();

      const response = await signInWithEmailAndPassword(auth, email, password);
      localStorage.setItem('token', response._tokenResponse.idToken);
      localStorage.setItem('uid', response.user.uid);
      navigate('/dashboard');

    } catch (error) {
      setError(error.message);
    }
  };


  return (
    <>
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card overflow-hidden">
                <div className="bg-primary bg-soft mb-4">
                  <div className="row mt-4 mb-4">
                    <div className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to.</p>
                      </div>
                    </div>
                    <div className="col-5 align-self-end">
                      {/* <img
                        src="assets/images/profile-img.png"
                        alt=""
                        className="img-fluid"
                      /> */}
                    <a href="#" className="auth-logo-light login-auth-logo">
                      <div className="avatar-md mt-4 mb-4">
                      {/* <span className="avatar-title bg-light"> */}
                      <span className="logo-lg">
                        <img src="assets/images/logo.png" alt="" height="30" />
                      </span>
                      {/* </span> */}
                      </div>
                    </a>
                     
                      
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="p-2">
                    <form className="form-horizontal" onSubmit={handleSubmit}>
                      {error && <p style={{ color: 'red' }}>{error}</p>}
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Enter email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Enter password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="remember-check"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="remember-check"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
