import React, { useState, useEffect } from "react";
import {
  fetchOrders,
  fetchRevenue,
  ordersDataLatest,
} from "../services/firebaseService";
import { Loader2 } from "lucide-react";
import LiquidityChart from "./LiquidityChart";
import { db } from "../firebase";
import { useParams } from "react-router-dom";
import { collection, query, getDocs } from "firebase/firestore";
import {
  getFirestore,
  doc,
  runTransaction,
  where,
  orderBy,
} from "firebase/firestore";
import moment from "moment";
import 'moment-timezone';

function MainContent() {
  moment.tz.setDefault("America/New_York");
  const uid = localStorage.getItem("uid");
  const { id: orderId } = useParams();
  const [orders, setOrders] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [LatestOrder, setLatestOrder] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const currencySymbol = localStorage.getItem("currencySymbol") || "$";

  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: 'America/New_York'
    };
    return date.toLocaleString("en-US", options);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const ordersData = await fetchOrders();
      const revenueData = await fetchRevenue();
      const totalOrder = await ordersDataLatest();
      // setOrders(ordersData);
      // setRevenue(revenueData);
      // setLatestOrder(totalOrder);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFilterChange(filterType);
  }, [orders]);

  useEffect(() => {
    fetchOrderData();
  }, []);

  const fetchOrderData = async () => {
    try {
      const uid = localStorage.getItem("uid");
      const ordersQuery = query(collection(db, "all_orders", uid, "orders"));
      const querySnapshot = await getDocs(ordersQuery);
      const orders = [];
      querySnapshot.forEach((doc) => {
        orders.push(doc.data());
      });
      setOrderData(orders);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching order data:", error);
      setLoading(false);
    }
  };

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
  };

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  };
  //new
  const OrderStatus = {
    COMPLETED: "COMPLETED",
    PENDING: "PENDING",
    CANCELLED: "CANCELLED",
  };

  const handleStatusUpdate = async () => {
    try {
      if (!selectedOrder) return; // Return if selectedOrder is null

      const updatedStatus =
        selectedOrder.status === OrderStatus.PENDING
          ? OrderStatus.COMPLETED
          : OrderStatus.PENDING;

      // Update order status in Firestore
      const db = getFirestore();
      const orderRef = doc(db, "all_orders", uid, "orders", orderId);
      await runTransaction(db, async (transaction) => {
        const orderDoc = await transaction.get(orderRef);
        if (!orderDoc.exists()) {
          throw new Error("Order document does not exist!");
        }
        transaction.update(orderRef, { status: updatedStatus });
        setSelectedOrder({ ...selectedOrder, status: updatedStatus }); // Update selectedOrder state
      });
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  // const fetchNewOrderData = async (startDate, endDate, type) => {
  //   try {
  //     if (!uid) {
  //       throw new Error("uid is undefined");
  //     }

  //     // const start = new Date(startDate);
  //     // const end = new Date(endDate);
  //     // end.setDate(end.getDate() + 1);
  //     const start = moment(startDate);
  //     const end = moment(endDate);
  
  //     // Set timezone to America/New_York
  //     start.tz('America/New_York');
  //     end.tz('America/New_York');

  //     let ordersQuery;
  //     if (type !== undefined) {
  //       if (type === "yesterday") {
  //         end.setDate(end.getDate() - 1)
  //         start.setDate(start.getDate() + 1)

  //         ordersQuery = query(
  //           collection(db, "all_orders", uid, "orders"),
  //           where("created_at", ">=", start),
  //           where("created_at", "<=", end),
  //           orderBy("created_at", "desc")
  //         );
  //       } else {
  //         start.setDate(start.getDate() -1)
        
  //         end.setDate(end.getDate() + 1)
         
          
  //         ordersQuery = query(
  //           collection(db, "all_orders", uid, "orders"),
  //           where("created_at", ">=", start),
  //           where("created_at", "<=", end),
  //           orderBy("created_at", "desc")
  //         );
  //       }
  //     } else {
  //       ordersQuery = query(
  //         collection(db, "all_orders", uid, "orders"),
  //         where("created_at", ">=", start),
  //         where("created_at", "<=", end),
  //         orderBy("created_at", "desc")
  //       );
  //     }

  //     const querySnapshot = await getDocs(ordersQuery);

  //     const orderData = querySnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));


  //     let totalRevenue = 0;
  //     let totalOrderCount = 0;

  //     orderData.forEach((doc) => {
  //       if (doc.status === "COMPLETED") {
  //       }
  //       totalRevenue += parseFloat(doc.total_cost) || 0;
  //       totalOrderCount++;
  //     });

  //     setLatestOrder(orderData);
  //     setRevenue(totalRevenue);
  //     setOrders(totalOrderCount);
  //   } catch (error) {
  //     console.error("Error fetching order data:", error);
  //     throw error;
  //   }
  // };

  const fetchNewOrderData = async (startDate, endDate, type) => {
    try {
        if (!uid) {
            throw new Error("uid is undefined");
        }

        const start = moment(startDate).tz('America/New_York').startOf('day'); // Set start of day
     

        const end = moment(endDate).tz('America/New_York').endOf('day'); // Set end of day


        let ordersQuery;
        if (type !== undefined) {
            if (type === "yesterday") {
              start.subtract(1, 'day').add(1, 'day');
              end.subtract(1, 'day').add(1, 'day');
            } else {
                // start.subtract(1, 'day');
                // end.add(1, 'day');
            }
        } else {
            // No adjustment needed for today
        }

        ordersQuery = query(
            collection(db, "all_orders", uid, "orders"),
            where("created_at", ">=", start.toDate()),
            where("created_at", "<=", end.toDate()),
            orderBy("created_at", "desc")
        );

        const querySnapshot = await getDocs(ordersQuery);

        const orderData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        let totalRevenue = 0;
        let totalOrderCount = 0;

        orderData.forEach((doc) => {
            if (doc.status === "COMPLETED") {
            }
            totalRevenue += parseFloat(doc.total_cost) || 0;
            totalOrderCount++;
        });

        setLatestOrder(orderData);
        setRevenue(totalRevenue);
        setOrders(totalOrderCount);
    } catch (error) {
        console.error("Error fetching order data:", error);
        throw error;
    }
};


  //-----------------filter Chart Data -------------------
  const [filteredData, setFilteredData] = useState([]);
  const [filterType, setFilterType] = useState("today");

  const handleChange = (e) => {
    handleFilterChange(e.target.value);
  };

  const handleFilterChange = (filter) => {
    setFilterType(filter);
    let filtered = [];
    const today = new Date();
    switch (filter) {
      case "today":
        filtered = filterDataByDateRange(today, today);
        fetchNewOrderData(today, today,"today");
        break;
      case "yesterday":
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        filtered = filterDataByDateRange(yesterday, yesterday);
        fetchNewOrderData(yesterday, yesterday, "yesterday");
        break;
      case "last7days":
        const last7days = new Date(today);
        last7days.setDate(today.getDate() - 7);
        filtered = filterDataByDateRange(last7days, today);
        fetchNewOrderData(last7days, today);
        break;
      case "last30days":
        const last30days = new Date(today);
        last30days.setDate(today.getDate() - 30);
        filtered = filterDataByDateRange(last30days, today);
        fetchNewOrderData(last30days, today);
        break;
      case "last90days":
        const last90days = new Date(today);
        last90days.setDate(today.getDate() - 90);
        filtered = filterDataByDateRange(last90days, today);
        fetchNewOrderData(last90days, today);
        break;
      case "last365days":
        const last365days = new Date(today);
        last365days.setDate(today.getDate() - 365);
        filtered = filterDataByDateRange(last365days, today);
        fetchNewOrderData(last365days, today);
        break;
      case "lastmonth":
        const lastMonthStart = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
        filtered = filterDataByDateRange(lastMonthStart, lastMonthEnd);
        fetchNewOrderData(lastMonthStart, lastMonthEnd);
        break;
      case "last12months":
        const last12MonthsStart = new Date(
          today.getFullYear() - 1,
          today.getMonth(),
          today.getDate()
        );
        const last12MonthsEnd = new Date(today);
        filtered = filterDataByDateRange(last12MonthsStart, last12MonthsEnd);
        fetchNewOrderData(last12MonthsStart, last12MonthsEnd);
        break;
      case "lastyear":
        const lastYearStart = new Date(today.getFullYear() - 1, 0, 1);
        const lastYearEnd = new Date(today.getFullYear() - 1, 11, 31);
        filtered = filterDataByDateRange(lastYearStart, lastYearEnd);
        fetchNewOrderData(lastYearStart, lastYearEnd);
        break;
      default:
        break;
    }
    setFilteredData(filtered);
  };

  // const filterDataByDateRange = (startDate, endDate) => {
  //   let countData = {};

  //   orderData.map((order) => {
  //     const dateFormate = moment(order.created_at.toDate()).format(
  //       "YYYY-MM-DD"
  //     );
  //     countData[dateFormate] = (countData[dateFormate] || 0) + 1;
  //   });

  //   const newArr = [];
  //   const currentDate = new Date(startDate);
  //   while (currentDate <= endDate) {
  //     // const formattedDate = moment(currentDate).format("YYYY-MM-DD");
  //     const formattedDate = moment.tz(currentDate, "America/New_York").format("YYYY-MM-DD");

  //     if (countData[formattedDate] || 0) {
  //       newArr.push({
  //         date: formattedDate,
  //         totalOrder: countData[formattedDate],
  //       });
  //     } else {
  //       newArr.push({
  //         date: formattedDate,
  //         totalOrder: 0,
  //       });
  //     }
  //     currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  //   }

  //   return newArr;
  // };

  const filterDataByDateRange = (startDate, endDate) => {
    let countData = {};
  
    orderData.map((order) => {
      const dateFormate = moment(order.created_at.toDate()).format(
        "YYYY-MM-DD"
      );
      countData[dateFormate] = (countData[dateFormate] || 0) + 1;
    });
  
    const newArr = [];
    const startDateTime = moment.tz(startDate, "America/New_York");
    const endDateTime = moment.tz(endDate, "America/New_York");
  
    const currentDate = startDateTime.clone();

    while (currentDate <= endDateTime) {
      const formattedDate = moment.tz(currentDate, "America/New_York").format("YYYY-MM-DD");
      
  
      if (countData[formattedDate] || 0) {
        newArr.push({
          date: formattedDate,
          totalOrder: countData[formattedDate],
        });
      } else {
        newArr.push({
          date: formattedDate,
          totalOrder: 0,
        });
      }
      currentDate.add(1, 'day'); // Move to the next day
    }
  
    return newArr;
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Dashboard</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card mini-stats-wid">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">Orders</p>
                            <h4 className="mb-0">{orders}</h4>
                          </div>

                          <div className="flex-shrink-0 align-self-center">
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                              <span className="avatar-title">
                                <i className="bx bx-copy-alt font-size-24"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card mini-stats-wid">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">Revenue</p>
                            <h4 className="mb-0">{`${currencySymbol}${revenue}`}</h4>
                          </div>

                          <div className="flex-shrink-0 align-self-center ">
                            <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i className="bx bx-archive-in font-size-24"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    {loading ? (
                      <Loader2
                        className="mr-2 h-4 w-4 animate-spin mx-auto"
                        style={{ height: 50, width: 50, color: "grey" }}
                      />
                    ) : (
                      <>
                        <div className="d-sm-flex flex-wrap">
                          <div className="ms-auto">
                            <select
                              className="form-select mb-2"
                              onChange={handleChange}
                            >
                              <option value="today">Today</option>
                              <option value="yesterday">Yesterday</option>
                              <option value="last7days">Last 7 days</option>
                              <option value="last30days">Last 30 days</option>
                              <option value="last90days">Last 90 days</option>
                              <option value="last365days">Last 365 days</option>
                              <option value="lastmonth">Last Month</option>
                              <option value="last12months">
                                Last 12 Months
                              </option>
                              <option value="lastyear">Last Year</option>
                            </select>
                          </div>
                        </div>

                        <div
                          id="stacked-column-chart"
                          className="apex-charts"
                          data-colors='["--bs-primary", "--bs-warning", "--bs-success"]'
                          dir="ltr"
                        ></div>
                        <div className="mt-8 grid gap-6 sm:my-10 md:grid-cols-2">
                          <LiquidityChart data={filteredData} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Latest Orders</h4>
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th className="align-middle">Order ID</th>
                            <th className="align-middle">Billing Name</th>
                            <th className="align-middle">Date</th>
                            <th className="align-middle">Total</th>
                            <th className="align-middle">Payment Status</th>
                            <th className="align-middle">Order Status</th>
                            <th className="align-middle">View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td colSpan="7" style={{ alignItems: "center" }}>
                                <Loader2 className="mr-2 h-4 w-4 animate-spin mx-auto" />
                              </td>
                            </tr>
                          ) : LatestOrder?.length ? (
                            LatestOrder.map((order) => (
                              <tr key={order.id}>
                                <td>
                                  <b>{order.id}</b>
                                </td>
                                <td>{`${order.firstName} ${order.lastName}`}</td>
                                <td>
                                  {order.created_at
                                    ? formatDate(order.created_at.seconds)
                                    : "N/A"}
                                </td>
                                <td>
                                  {order.total_cost
                                    ? `${currencySymbol}${order.total_cost}`
                                    : "N/A"}
                                </td>
                                <td>
                                  <span
                                    className={`badge badge-pill ${
                                      order.payment_status === "COMPLETED"
                                        ? "badge-soft-success"
                                        : "badge-soft-danger"
                                    } font-size-11`}
                                  >
                                    {order.payment_status}
                                  </span>
                                </td>
                                <td>
                                  <i
                                    className={`fab ${
                                      order?.status
                                        ? capitalizeFirstLetter(order.status)
                                        : ""
                                    } me-1`}
                                  ></i>{" "}
                                  {order?.status
                                    ? capitalizeFirstLetter(order.status)
                                    : ""}
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm viewmore-eye-button"
                                    data-bs-toggle="modal"
                                    data-bs-target=".transaction-detailModal"
                                    onClick={() => handleViewDetails(order)}
                                  >
                                    View Details
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7">No records found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade transaction-detailModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="transaction-detailModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="transaction-detailModalLabel">
                  Order Details
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {selectedOrder && (
                <div className="modal-body">
                  <p className="mb-2">
                    Product id:{" "}
                    <span className="text-primary">{selectedOrder.id}</span>
                  </p>
                  <p className="mb-4">
                    Billing Name:{" "}
                    <span className="text-primary">{`${selectedOrder.firstName} ${selectedOrder.lastName}`}</span>
                  </p>
                  <div className="d-flex justify-content-end mb-2">
                    <button
                      className={`btn btn-${
                        selectedOrder.status === OrderStatus.PENDING
                          ? "success"
                          : "warning"
                      } mx-2 btn-sm`}
                      onClick={handleStatusUpdate}
                    >
                      Set{" "}
                      {selectedOrder.status === OrderStatus.PENDING
                        ? "Complete"
                        : "Pending"}
                    </button>
                  </div>
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Price</th>
                          <th scope="col">Order Quantity</th>
                          <th scope="col">Customer Info</th>
                          <th scope="col">Delivery Type</th>
                          <th scope="col">Address</th>
                          <th scope="col">Status</th>
                          <th scope="col">Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <h5 className="text-truncate font-size-14">
                                {selectedOrder.created_at
                                  ? formatDate(selectedOrder.created_at.seconds)
                                  : "N/A"}
                              </h5>
                              <p className="text-muted mb-0">
                                {selectedOrder.status}
                              </p>
                            </div>
                          </td>
                          <td>
                            {selectedOrder.total_cost
                              ? `${currencySymbol}${selectedOrder.total_cost}`
                              : "N/A"}
                          </td>
                          <td>
                            {selectedOrder.quantity
                              ? selectedOrder.quantity
                              : "N/A"}
                          </td>
                          <td>
                            {selectedOrder.firstName
                              ? selectedOrder.firstName
                              : "N/A"}{" "}
                            {selectedOrder.lastName
                              ? selectedOrder.lastName
                              : "N/A"}
                          </td>
                          <td>
                            {selectedOrder.storePickup
                              ? "Store Pickup"
                              : "Delivery"}
                          </td>
                          <td>
                            {selectedOrder.address
                              ? `${selectedOrder.address}, ${selectedOrder.city}, ${selectedOrder.zip}, ${selectedOrder.state}, ${selectedOrder.country}`
                              : "N/A"}
                          </td>
                          <td>
                            <div
                              className={`badge bg-${
                                selectedOrder.status === "PENDING"
                                  ? "warning"
                                  : "success"
                              }`}
                            >
                              {selectedOrder.status}
                            </div>
                          </td>
                          <td>
                            <a
                              href={selectedOrder.link}
                              className="btn btn-primary"
                            >
                              <i
                                className="fa fa-download"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary download-red-button"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainContent;
