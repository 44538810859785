import React, { useState, useEffect } from "react";
import { getFirestore, doc, getDoc, runTransaction } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

const OrderStatus = {
  COMPLETED: "COMPLETED",
  PENDING: "PENDING",
  CANCELLED: "CANCELLED",
};

function OrderView() {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const uid = localStorage.getItem("uid");
  const { id: orderId } = useParams();
  const navigate = useNavigate();
  const currencySymbol = localStorage.getItem('currencySymbol');

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const db = getFirestore();
        const orderRef = doc(db, "all_orders", uid, "orders", orderId);
        const orderSnapshot = await getDoc(orderRef);
        if (orderSnapshot.exists()) {
          setOrder({ ...orderSnapshot.data(), id: orderSnapshot.id });
        } else {
          console.log("No such document!");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching order:", error);
        setLoading(false);
      }
    };

    fetchOrder();
  }, [orderId, uid]);

  const handleStatusUpdate = async () => {
    try {
      const updatedStatus =
        order.status === OrderStatus.PENDING
          ? OrderStatus.COMPLETED
          : OrderStatus.PENDING;

      // Update order status in Firestore
      const db = getFirestore();
      const orderRef = doc(db, "all_orders", uid, "orders", orderId);
      await runTransaction(db, async (transaction) => {
        const orderDoc = await transaction.get(orderRef);
        if (!orderDoc.exists()) {
          throw new Error("Order document does not exist!");
        }
        transaction.update(orderRef, { status: updatedStatus });
        setOrder({ ...order, status: updatedStatus }); // Update local state
      });
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true
    };
    return date.toLocaleString("en-US", options);
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!order) {
    return <div>No order found</div>;
  }

  return (
    <>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="card shadow">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="card-title mb-4">Order Details</h4>
                      <div>
                        <button className={`btn btn-${order.status === OrderStatus.PENDING ? 'success' : 'warning'} mx-2 btn-sm`} onClick={handleStatusUpdate}>
                          Set {order.status === OrderStatus.PENDING ? 'Complete' : 'Pending'}
                        </button>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h5 className="card-subtitle">Order</h5>

                    </div>
                    <div className="mb-4">
                      <label className="fw-bold">Order Id:</label>
                      <span className="ms-2">{order.id}</span>
                    </div>
                    <div className="mb-4">
                      <label className="fw-bold">Order Date:</label>
                      <span className="ms-2"> {order.created_at ? formatDate(order.created_at.seconds) : "N/A"}</span>
                    </div>
                    <div className="mb-4">
                      <label className="fw-bold">Order Quantity:</label>
                      <span className="ms-2"> {order.quantity ? order.quantity : "N/A"}</span>
                    </div>
                    <div className="mb-4">
                      <label className="fw-bold">Order Value:</label>
                      <span className="ms-2"> {order.total_cost ? `${currencySymbol}${order.total_cost}` : "N/A"}</span>
                    </div>
                    <div className="mb-4">
                      <label className="fw-bold">Customer Info:</label>
                      <span className="ms-2">{order.firstName ? order.firstName : "N/A"} {order.lastName ? order.lastName : "N/A"}</span>

                    </div>
                    <div className="mb-4">
                      <label className="fw-bold">Delivery Type:</label>
                      <span className="ms-2">{order.storePickup ? "Store Pickup" : "Delivery"}</span>
                    </div>
                    <div className="mb-4">
                      <label className="fw-bold">Address:</label>
                      <span className="ms-2">
                        {order.address ? `${order.address}, ${order.city}, ${order.zip}, ${order.state}, ${order.country}` : "N/A"}
                      </span>
                    </div>
                    <div className="mb-4">
                      <label className="fw-bold">Status: </label>
                      <span className="ms-2">
                        <div className={`badge bg-${order.status === OrderStatus.PENDING ? 'warning' : 'success'}`}>{order.status}</div>
                      </span>
                    </div>
                    <div>
                      <a href={order.link} className="btn btn-primary download-red-button"><i className="fa fa-download" aria-hidden="true"></i> Download Order</a>
                      <button className="btn btn-secondary ms-2 viewmore-eye-button" onClick={() => navigate(-1)}>Back</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OrderView;
