import React, { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { useFirebase } from "../context/SettingAction";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";

const schema = z.object({
  adminEmail: z.string(),
  emailProtocol: z.string(),
  serverHost: z.string(),
  serverPassword: z.string(),
  serverPort: z.coerce.number(),
  serverUsername: z.string(),
  supportEmail: z.string(),
});

function EmilSettings({
  adminEmail,
  emailProtocol,
  serverHost,
  serverPassword,
  serverPort,
  serverUsername,
  supportEmail,
}) {
  const [loading, setLoading] = useState(false);
  const { updateEmailSetting } = useFirebase();
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      adminEmail,
      emailProtocol,
      serverHost,
      serverPassword,
      serverPort,
      serverUsername,
      supportEmail,
    },
  });

  const onSubmitAction = async (data) => {
    setLoading(true);
    await updateEmailSetting(data);
    setLoading(false);
    toast.success(`Updated Bleed Preferences`);
  };

  return (
    <Card className="max-w-[560px] sm:w-full">
      <CardHeader>
        <CardTitle>Email Settings</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <form
            id="email_settings_form"
            className="grid gap-1  w-full"
            onSubmit={form.handleSubmit(onSubmitAction)}
          >
            <div className="flex flex-col space-y-2">
              <div className="text-sm">Administrator Email Address</div>
              <Input {...form.register("adminEmail")} />
            </div>
            <div className="text-sm">Email Protocol</div>
            <Input {...form.register("emailProtocol")} />
            <div className="flex flex-col space-y-2"></div>
            <div className="flex flex-col space-y-2">
              <div className="text-sm">SMTP Server Host</div>
              <Input {...form.register("serverHost")} />
            </div>
            <div className="flex flex-col space-y-2">
              <div className="text-sm">SMTP Server Password</div>
              <Input {...form.register("serverPassword")} />
            </div>
            <div className="flex flex-col space-y-2">
              <div className="text-sm">SMTP Server Port</div>
              <Input
                type="number"
                {...form.register("serverPort")}
              />
            </div>
            <div className="flex flex-col space-y-2">
              <div className="text-sm">SMTP Server User Name</div>
              <Input {...form.register("serverUsername")} />
            </div>
            <div className="flex flex-col space-y-2">
              <div className="text-sm">Support Email Address</div>
              <Input {...form.register("supportEmail")} />
            </div>
          </form>
        </div>
      </CardContent>
      <CardFooter>
        <Button
          variant="outline"
          type="submit"
          className="w-full save-currency-btn"
          form="email_settings_form"
        >
          {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          Save Email Settings
        </Button>
      </CardFooter>
    </Card>
  );
}

export default EmilSettings;
