import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Sidebar() {
  const location = useLocation();
  const [isToggled, setIsToggled] = useState(false);

  // Define a function to determine if a given link is active
  const isLinkActive = (link) => {
    return location.pathname === link ? 'active' : '';
  };

  const handleClick = () => {
    setIsToggled(!isToggled);
  };
  
  if (isToggled) {
    document.body.classList.add("SideBarShow");
  } else {
    document.body.classList.remove("SideBarShow");
  }
  return (
    <>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title" key="t-menu">Menu</li>
              <li>
                <Link to="/dashboard" className={isLinkActive('/dashboard')}>
                  <i className="bx bx-home-circle"></i>
                  <span key="t-dashboards">Dashboards</span>
                </Link>
              </li>
                  {/* <li><Link to="/users" className={isLinkActive('/users')} key="t-crypto">Users</Link></li> */}
                  <li><Link to="/orders" className={isLinkActive('/orders')} key="t-default"><i className="bx bx-cart"></i> Order</Link></li>
                  <li><Link to="/settings" className={isLinkActive('/settings')} key="t-default"><i className="bx bx-cog"></i> Settings</Link></li>
            </ul>
          </div>
        </div>
        <button
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item waves-effect sidebar-toggle-btn"
              id="vertical-menu-btn" onClick={handleClick}
            >
              <i className="fa fa-fw fa-angle-left"></i>
            </button>
      </div>
    </>
  );
}

export default Sidebar;
