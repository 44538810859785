import { clsx } from 'clsx';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import { twMerge } from 'tailwind-merge';


dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function formattedDate(date) {
  return dayjs.utc(date).local().format('LLL');
}

export const getSymbolForCurrency = (currencyCode) => {
  switch (currencyCode) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "CAD":
      return "CA$";
    case "AUD":
      return "A$";
    case "GBP":
      return "£";
    default:
      return "";
  }
};

export const GLOBAL_DPI = 300;

export const inTo300DpiPx = (inches) => {
  return Number((inches * GLOBAL_DPI).toFixed(3));
};

export const cmToIn = (cm) => {
  return Number((cm / 2.54).toFixed(3));
};
