import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./pages/LoginPage";
import PrivateRoute from "./routes/PrivateRoute";
import UserListing from "./pages/UserListing";
import OrderListing from "./pages/OrderListing";
import { AuthProvider } from "./context/AuthContext";
import OrderView from "./pages/OrderView";
import Settings from "./pages/Settings";
import { FirebaseProvider } from "./context/SettingAction";
import { CurrencyProvider } from "./context/CurrencyContext";
import AutoLogout from "./components/AutoLogout";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <AuthProvider>
      <FirebaseProvider>
        <Router>
          <AutoLogout />
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route
              path="/dashboard"
              element={<PrivateRoute children={<Dashboard />} />}
            />
            <Route
              path="/users"
              element={<PrivateRoute children={<UserListing />} />}
            />
            <Route
              path="/orders"
              element={<PrivateRoute children={<OrderListing />} />}
            />
            <Route
              path="/order/:id"
              element={<PrivateRoute children={<OrderView />} />}
            />
            <Route
              path="/settings"
              element={<PrivateRoute children={<Settings />} />}
            />
          </Routes>
        </Router>
        <ToastContainer />
      </FirebaseProvider>
    </AuthProvider>
  );
}

export default App;
