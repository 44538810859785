import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
     
      navigate('/');
    }
  }, [token, navigate]);

  return token ? (
    <>
      <Header />
      <div className="dashboard-sec">
        <div className='fix-left-side' id="navbarSupportedContent">
          <Sidebar />
        </div>
        <div className='main-right-box'>
          {children}
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/" replace />
  );
};

export default PrivateRoute;
