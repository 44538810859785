import React, { useState, useEffect, useCallback } from "react";
import { useFirebase } from "../context/SettingAction";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-hot-toast";
import { FaTrashAlt } from "react-icons/fa";
import { z } from "zod";
import { nanoid } from "nanoid";

import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Separator } from "../components/ui/separator";
import { cmToIn, inTo300DpiPx } from "../lib/utils";

export const schema = z.object({
  width: z.coerce.number(),
  height: z.coerce.number(),
  price: z.coerce.number(),
  unit: z.union([z.literal("cm"), z.literal("in")]),
});

const SizeSettings = (/*{ sheetSizes } */) => {
  // const [loading, setLoading] = useState(false);
  // const [sizes, setSizes] = useState([]);
  // const { updateSheetSize, removeOrder } = useFirebase();
  // const {
  //   handleSubmit,
  //   formState: { errors },
  //   register,
  //   control,
  //   reset,
  //   watch,
  // } = useForm({
  //   resolver: zodResolver(schema),
  //   defaultValues: {
  //     width: 1,
  //     price: 1,
  //     height: 1,
  //     unit: "in",
  //   },
  // });

  // const unit = watch("unit");

  // const onSubmit = async (data) => {
  //   setLoading(true);
  //   await updateSheetSize(data);
  //   setSizes([...sizes, data]);
  //   reset(); // Reset form after submission
  //   toast.success(`Updated sizes`, {});
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   setSizes(sheetSizes);
  // }, [sheetSizes]);

  const { configs } = useFirebase();

  const [loading, setLoading] = useState(false);
  const { updateSheetSize, removeOrder } = useFirebase();
  const schema2 = z.object({
    sizes: z.array(
      z.object({
        width: z.coerce.number(),
        height: z.coerce.number(),
        price: z.coerce.number(),
        unit: z.union([z.literal("cm"), z.literal("in")]),
      })
    ),
  });

  const form = useForm({
    resolver: zodResolver(schema2),
    defaultValues: {
      sizes: configs?.sizes || [],
    },
  });

  const sizesFieldArray = useFieldArray({
    control: form.control,
    name: "sizes",
    rules: {},
  });
  const watchedSheetSize = form.watch("sizes", sizesFieldArray.fields);

  const onEditSheetSubmit = async (data) => {
    try {
      setLoading(true);
      const normalised = watchedSheetSize.map((size) => ({
        ...size,
        width:
          size.unit === "cm"
            ? Math.min(size.width, 109 * 2.54)
            : Math.min(size.width, 109),
        height:
          size.unit === "cm"
            ? Math.min(size.height, 109 * 2.54)
            : Math.min(size.height, 109),
        computedWidth:
          size.unit === "in"
            ? inTo300DpiPx(size.width)
            : inTo300DpiPx(cmToIn(size.width)),
        computedHeight:
          size.unit === "in"
            ? inTo300DpiPx(size.height)
            : inTo300DpiPx(cmToIn(size.height)),
      }));
      await updateSheetSize(normalised);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Card className="max-w-[560px] sm:w-full">
      <CardHeader>
        <CardTitle>Sheet Size & Pricing</CardTitle>
        <CardDescription>
          The gang sheet sizes available to your customers and their prices. The
          current max size is 108x108 inches (274 x 274 cm).
        </CardDescription>
      </CardHeader>

      {!configs?.sizes ? (
        <CardContent>
          <Loader2 className="mr-2 h-8 w-8 animate-spin" />
        </CardContent>
      ) : (
        <CardContent>
          <div className="space-y-4">
            <form onSubmit={form.handleSubmit(onEditSheetSubmit)}>
              <div className="grid gap-3 w-full">
                {sizesFieldArray?.fields?.map((size, idx) => (
                  <div
                    key={size.id}
                    className="flex items-center justify-between space-x-4 flexDiv-sizesheet"
                  >
                    <div className="flex flex-col flex-1">
                      <div className="text-sm">Width</div>
                      <Input
                        min={1}
                        max={size.unit === "in" ? 109 : 276.86}
                        step={0.01}
                        type="number"
                        defaultValue={size.width}
                        {...form.register(`sizes.${idx}.width`)}
                      />
                    </div>
                    <div className="flex flex-col flex-1">
                      <div className="text-sm">Height</div>
                      <Input
                        min={1}
                        max={size.unit === "in" ? 108 : 274}
                        type="number"
                        defaultValue={size.height}
                        {...form.register(`sizes.${idx}.height`)}
                      />
                    </div>
                    <div className="flex flex-col flex-1">
                      <div className="text-sm">Unit</div>
                      <Controller
                        control={form.control}
                        name={`sizes.${idx}.unit`}
                        rules={{ required: true }}
                        defaultValue={size.unit} // Ensure defaultValue is set correctly
                        render={({ field }) => (
                          <select
                            value={field.value}
                            onChange={(value) => field.onChange(value)}
                            className="flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1"
                          >
                            <option value="">Select Unit</option>
                            <option value="in">in</option>
                            <option value="cm">cm</option>
                          </select>
                          // <Select
                          //   value={field.value}
                          //   onValueChange={(value) => field.onChange(value)}
                          // >
                          //   <SelectTrigger className="ml-auto">
                          //     <SelectValue placeholder="Select Unit" />
                          //   </SelectTrigger>
                          //   <SelectContent>
                          //     <SelectItem value="in">in</SelectItem>
                          //     <SelectItem value="cm">cm</SelectItem>
                          //   </SelectContent>
                          // </Select>
                        )}
                      />
                      {/* <select
                      {...form.register(`sizes.${idx}.unit`)}
                      defaultValue={size.unit}
                    >
                      <option value="in">in</option>
                      <option value="cm">cm</option>
                    </select> */}
                    </div>
                    <div className="flex flex-col flex-1 flex-Div-margin">
                      <div className="text-sm">Price</div>
                      <Input
                        min={1}
                        step={0.01}
                        type="number"
                        defaultValue={size.price}
                        {...form.register(`sizes.${idx}.price`)}
                        required
                      />
                    </div>
                    <div className="font-medium border-r-0 self-end trash-button rounded">
                      <Button
                        type="button"
                        onClick={() => sizesFieldArray.remove(idx)}
                        size="sm"
                        className="trash-button"
                      >
                        <FaTrashAlt />
                      </Button>
                    </div>
                  </div>
                ))}

                <div className="flex flex-col items-end">
                  <Button
                    className="font-medium border-r-0 add-item-order-btn"
                    size="sm"
                    variant="default"
                    onClick={() => {
                      sizesFieldArray.append({
                        width: 1,
                        height: 1,
                        unit: "in",
                        key: nanoid(),
                      });
                    }}
                  >
                    Add Size
                  </Button>
                </div>
                <div>
                  <Button
                    type="submit"
                    className="w-full save-currency-btn"
                    size="sm"
                    variant="default"
                  >
                    {loading && (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Save Sheet Size Settings
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </CardContent>
      )}

      {/* <CardContent>
        <div className="space-y-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-1 w-full">
              <div className="flex items-center justify-between space-x-4 flexDiv-sizesheet">
                <div className="flex flex-col flex-1">
                  <div className="text-sm">Width</div>
                  <Input
                    min={1}
                    step={0.1}
                    type="number"
                    max={unit === "in" ? 108 : 274}
                    {...register("width")}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                  {errors.width && (
                    <p className="text-red-500">{errors.width.message}</p>
                  )}
                </div>
                <div className="flex flex-col flex-1">
                  <div className="text-sm">Height</div>
                  <Input
                    min={1}
                    step={0.1}
                    type="number"
                    max={unit === "in" ? 108 : 274}
                    {...register("height")}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="flex flex-col flex-1">
                  <div className="text-sm">Unit</div>
                  <Controller
                    control={control}
                    name="unit"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                      onValueChange={(value) => {
                        field.onChange(value);
                      }}
                      >
                        <SelectTrigger className="ml-auto">
                          <SelectValue placeholder="Select Unit" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="in">in</SelectItem>
                          <SelectItem value="cm">cm</SelectItem>
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>
                <div className="flex flex-col flex-1 flex-Div-marging">
                  <div className="text-sm">Price</div>
                  <Input
                    min={1}
                    step={0.01}
                    type="number"
                    {...register("price")}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="flex flex-col items-end">
                <div className="text-sm opacity-0">Unit</div>
                <Button
                  type="submit"
                  className="font-medium border-r-0 add-item-order-btn"
                  size="sm"
                  variant="default"
                >
                  {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                  Add Size
                </Button>
              </div>
            </div>
          </form>
          <div className="my-4">
            <h4 className="text-lg font-bold mb-3 label-orange-title">
              All Sheets ({sizes?.length})
            </h4>
            {sizes?.map((size, index) => (
              <div key={index}>
                <SizeComponent
                  size={size}
                  removeOrder={removeOrder}
                  setLoading={setLoading}
                  setSizes={setSizes}
                  sizes={sizes}
                />
                <Separator />
              </div>
            ))}
          </div>
        </div>
      </CardContent>
      <CardFooter></CardFooter> */}
    </Card>
  );
};

// const SizeComponent = React.memo(
//   ({ size, removeOrder, setLoading, setSizes, sizes }) => {
//     const [loading, setLocalLoading] = useState(false);

//     const handleRemove = useCallback(async () => {
//       setLocalLoading(true);
//       await removeOrder(size);
//       setSizes((prevSizes) => prevSizes.filter((s) => s !== size));
//       toast.success(`Deleted Size.`);
//       setLoading(false);
//       setLocalLoading(false);
//     }, [removeOrder, size, setLoading, setSizes]);

//     return (
//       <div className="flex flex-row justify-between align-center py-3 cursor-pointer hover:opacity-80">
//         <div className="text-md flex-[0_0_100px] font-bold">
//           {size.width} x {size.height} {size.unit}
//         </div>
//         <div className="text-md font-medium text-left">${size.price}</div>
//         <div className="text-md font-medium cursor-pointer hover:opacity-80">
//           <Button onClick={handleRemove} size="sm" className="trash-button">
//             {loading ? (
//               <Loader2 className="mr-2 h-4 w-4 animate-spin" />
//             ) : (
//               <FaTrashAlt />
//             )}
//           </Button>
//         </div>
//       </div>
//     );
//   }
// );

export default SizeSettings;
