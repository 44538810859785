import React from 'react';
import Footer from './Footer';
import Header from './Header';
import MainContent from './MainContent';
import Sidebar from './Sidebar';


function Dashboard() {
  return (
    <>
      <Header />
      <Sidebar />
      <MainContent />
      <Footer />
    </>

  )
}

export default Dashboard