import { initializeApp } from 'firebase/app';
import { getAuth} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBzbzo2WIN8500RRX_DzeRpO8_32sGTCvU",
  authDomain: "dtfbuulder.firebaseapp.com",
  projectId: "dtfbuulder",
  storageBucket: "dtfbuulder.appspot.com",
  messagingSenderId: "103655701196",
  appId: "1:103655701196:web:a5cc0cf77cab635fcf6069",
  measurementId: "G-440KTB287V",
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };