import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';

function UserListing() {
    const auth = useAuth();
    
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10); 
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOrderUserId, setSortOrderUserId] = useState('asc');
    const [sortOrderName, setSortOrderName] = useState('asc');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const db = getFirestore();
                const querySnapshot = await getDocs(collection(db, 'users'));
                const usersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setUsers(usersData);
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false); // Set loading to false whether data is fetched or there's an error
            }
        };
        fetchUsers();
    }, []);

    // Pagination Logic
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = users
        .filter(user => user.id.toLowerCase().includes(searchQuery.toLowerCase()))
        .slice(indexOfFirstUser, indexOfLastUser);

   
    const paginate = pageNumber => setCurrentPage(pageNumber);

    // Sorting Logic
    const handleSortUserId = () => {
        const sortedUsers = users.slice().sort((a, b) => {
            if (sortOrderUserId === 'asc') {
                return a.id.localeCompare(b.id);
            } else {
                return b.id.localeCompare(a.id);
            }
        });
        setUsers(sortedUsers);
        setSortOrderUserId(sortOrderUserId === 'asc' ? 'desc' : 'asc');
    };

    const handleSortName = () => {
        if (users.length === 0) return; 
        const sortedUsers = users.slice().sort((a, b) => {
            const nameA = a.name || '';
            const nameB = b.name || ''; 
            return sortOrderName === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        });
        setUsers(sortedUsers);
        setSortOrderName(sortOrderName === 'asc' ? 'desc' : 'asc');
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <>
            <Header user={auth.currentUser}/>
            <Sidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Users Listing</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Users</h4>
                                        <div className="mb-3">
                                        <div className="col-lg-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search by User ID"
                                                value={searchQuery}
                                                onChange={handleSearchInputChange}
                                            />
                                        </div>
                                        </div>
                                        {loading ? (
                                            <p>Loading...</p>
                                        ) : (
                                            <>
                                                {currentUsers.length === 0 ? (
                                                    <p>No records found.</p>
                                                ) : (
                                                    <div className="table-responsive">
                                                        <table className="table align-middle table-nowrap mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="align-middle" onClick={handleSortUserId} style={{ cursor: "pointer" }}>
                                                                        User ID
                                                                        {sortOrderUserId === 'asc' ? <i className="fas fa-sort-up"></i> : <i className="fas fa-sort-down"></i>}
                                                                    </th>
                                                                    <th className="align-middle" onClick={handleSortName} style={{ cursor: "pointer" }}>
                                                                        Name
                                                                        {sortOrderName === 'asc' ? <i className="fas fa-sort-up"></i> : <i className="fas fa-sort-down"></i>}
                                                                    </th>
                                                                    <th className="align-middle">Email</th>
                                                                    {/* Add more user fields here */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {currentUsers.map(user => (
                                                                    <tr key={user.id}>
                                                                        <td>{user.id}</td>
                                                                        <td>{user.name}</td>
                                                                        <td>{user.email}</td>
                                                                        {/* Render more user fields here */}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                                {users.length > usersPerPage && (
                                                    <nav>
                                                        <ul className="pagination justify-content-center">
                                                            {Array.from({length: Math.ceil(users.length / usersPerPage)}, (_, i) => (
                                                                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                                                    <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </nav>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default UserListing;
