import React, { useState } from "react";
// import { updateLegalName } from "@/actions/updateLegalName";
import { useFirebase } from "../context/SettingAction";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { Input } from "./ui/input";

const schema = z.object({
  legalName: z.string().min(1, { message: "Legal name is required" }),
});

function CopyrightNotice({ legalName }) {
  const{updateLegalName} =useFirebase();
  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      legalName,
    },
  });

  const name = form.watch("legalName");

  const onSubmitAction = async (data) => {
    setLoading(true);
    await updateLegalName(data);
    setLoading(false);
    toast.success(`Updated Copyright Settings`);
  };

  return (
    <Card className="max-w-[560px] sm:w-full">
      <CardHeader>
        <CardTitle className="flex flex-row justify-between">
          <div className="text-xl"> Copyright Notice Preferences</div>

          <Dialog>
            <DialogTrigger>
              <Button
                type="submit"
                className="font-medium border-r-0 add-item-order-btn"
                size="sm"
                variant="default"
                disabled={!name}
              >
                Preview
              </Button>
            </DialogTrigger>
            <DialogContent className="min-w-[700px] fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg popup-model translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg min-w-[700px]">
              <DialogHeader>
                <DialogTitle className="popup-heading">Copyright Notice</DialogTitle>
                <DialogDescription className="text-lg">
                  By submitting a design for printing, I ("Client", "You" or
                  "I") affirm to <span className="font-bold"> {name}</span> that
                  I possess the complete rights to the design and any and all
                  materials and information submitted by or on behalf of Client
                  to <span className="font-bold"> {name}</span>. If there is any
                  uncertainty or hesitation regarding ownership, I agree to
                  verify my rights or obtain permission from the copyright
                  owner.
                  <br />
                  Upon submission of a design, Client acknowledges and agrees to
                  the following terms: As the Client, I represent and warrant
                  that: (1) I have the right and power to enter into this
                  agreement; (2) I will comply with all applicable laws and
                  regulations; (3) I either own or have the authority, license,
                  or permission to use and reproduce all material, content,
                  designs, and information provided to
                  <span className="font-bold"> {name}</span>. I understand and
                  agree that in the event that the legal owner of the design
                  contacts the fulfillment house and/or{" "}
                  <span className="font-bold"> {name}</span>, they will be
                  directed to me (Client). I agree to indemnify and defend
                  (including reasonable attorney fees an court costs){" "}
                  <span className="font-bold"> {name}</span> against any claims,
                  including third-party claims, arising from Client's breach of
                  this agreement. Client further agrees that it may not settle
                  any such claim without
                  <span className="font-bold"> {name}</span>'s prior written
                  consent. (this is for the checkmark acceptance)I, Client,
                  comprehend that the unlawful use of third-party copyright
                  content is a serious offense and may result in penalties.
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        </CardTitle>
        <CardDescription>
          Ask your customers to confirm they uploading artwork they have legal
          rights to use.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid gap-6">
        <form
          id="copyright"
          onSubmit={form.handleSubmit(onSubmitAction)}
          className="grid gap-6"
        >
          <div className="flex flex-col space-y-2">
            <div className="text-sm font-medium formlabel-fullwidth-title">Legal Name</div>
            <Input {...form.register("legalName")} />
          </div>
        </form>
      </CardContent>
      <CardFooter>
        <Button
          variant="outline"
          type="submit"
          form="copyright"
          className="w-full save-currency-btn"
          disabled={!name}
        >
          {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          Save Preferences
        </Button>
      </CardFooter>
    </Card>
  );
}

export default CopyrightNotice;
